<template>
  <!-- Container fluid  -->
  <div class="container-fluid">
      <!-- Start Page Content -->
      <div class="row">
          <div class="col-12">
              <div class="card">
                  <div id="report" class="card-body">
                      <div class="container-fluid">
                        <div style="width: 80px;">
                          <img v-bind:src="$store.state.logo" style="max-width:100%; max-height:100%; display:block;"/>
                        </div>
                        <div class="headerreport">
                          <div class="row">
                            {{ companydata.cmp_nmbre }}
                          </div>
                          <div class="row">
                            {{ fecha }}
                          </div>
                        </div>
                      </div>
                      <br>
                      <div class="card-title">
                        <h1 class="titlereport">Reporte de Tamaño de Repositorio de Archivos adjuntos</h1>
                        <h5 class="subtitle">{{ rango }}</h5>
                      </div>
                      <div id="wrapping" class="table-responsive m-t-40 bodyreport">
                          <table id="reporteTamanoRepositorio" class="table table-sm display nowrap table-striped table-bordered" cellspacing="0" width="100%">
                              <thead>
                                  <tr>
                                    <th>Tamaño (GB)</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="(item, index) in items" class="rowstyle">
                                    <td class="text-right">{{ item.tamano }}</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                      <br>
                      <div v-if="showProgress" style="text-align:center">
                        <v-progress-circular
                          :size="50"
                          :width="5"
                          color="blue"
                          indeterminate
                        ></v-progress-circular>
                      </div>
                  </div>
                  <br>
                  <div class="row justify-content-around" align="center">
                    <button type="button" class="btn btn-primary" v-on:click="printReport('report')">Imprimir</button>
                    <button type="button" id="customXLSButton" class="btn btn-primary" v-on:click="exportReport">Exportar</button>
                  </div>
              </div>
          </div>
      </div>
      <!-- End PAge Content -->
  </div>
  <!-- End Container fluid  -->
</template>

<script>
//import { VProgressCircular } from 'vuetify/lib';
import moment from 'moment';
import print from 'print-js';
import XLSX from 'xlsx';

export default {
  components: { 
    //VProgressCircular
  },
  data(){
    return{
      message: '',
      items: [],
      debug: null,
      fecha: null,
      rango: '',
      dcm_fcharadini: '',
      dcm_fcharadfin: '',
      showProgress: false
    }
  },
  computed:{
    companydata() {
      return this.$store.state.companydata
    }
  },
  created: function(){
    this.fecha = moment(new Date()).format('DD/MMM/YYYY hh:mm A');
    this.debug = this.$route.params;
    this.dcm_fcharadini = this.$route.params.query.dcm_fcharadini;
    this.dcm_fcharadfin = this.$route.params.query.dcm_fcharadfin;

    this.rango = "Desde: " + moment(this.dcm_fcharadini).format('DD/MMM/YYYY') + " --- Hasta: " + moment(this.dcm_fcharadfin).format('DD/MMM/YYYY');

    this.searchItems(this.$route.params.query);
  },
  mounted: function() {
    /*
    $('#myTable').DataTable({
    dom: 'Bfrtip',
    buttons: [
        'copy',
        'pdf',
        'excel'
    ]
    });
    */
  },
  methods: {
    searchItems(p){
      if (p != null){
        this.debug = p;
        this.showProgress = true;

        p.cmp_id = this.$store.state.company;
        //p.usu_id = String(this.$store.state.user);
        this.message = 'Buscando documentos';
        let uri = '/docs/report/tamanorepos';
        //this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.post(uri, p)
        .then((response) => {
            var items = response.data;

            for(let i = 0; i < items.length; i++){
                let item = {};
                item.tamano = Number(Math.round(parseInt(items[i].tamano)/1024**3)).toLocaleString();
                this.items.push(item);
            }
            this.showProgress = false;
        })
        .catch(err => {
          console.log(err);
          this.message = '¡Error al buscar los documentos' + err;
        });
      }
    },
    printReport(object){
      var report = window.document.getElementById('report');
      var p = window.open('', 'Imprimir');
      p.document.write(
                "<!DOCTYPE html>"+
                "<html>"+
                "<head>"+
                "<link rel='stylesheet' href='../../bootstrap/css/bootstrap.min.css'></link>"+
                "<link rel='stylesheet' href='../../css/report.css'></link>"+
                "</head>"+
                "<body>"+
                    report.innerHTML+
                "</body>"+
                "</html>");
      p.document.close();

      setTimeout(function(){ //giving it 200 milliseconds time to load
              p.focus();
              p.print();
              p.close();
      }, 2000);
    },
    exportReport(){
      var tableId = 'reporteTamanoRepositorio';

      var report = [];
      for (var i = 0; i < this.items.length; i++){
        let item = {};
        item.tamano = this.items[i].tamano;

        report.push(item);
      }

      /* generate a new workbook with the first rows */
      var ws = XLSX.utils.aoa_to_sheet([
        [this.companydata.cmp_nmbre],
        [this.fecha],
        [],
      	["Reporte de Tamaño de Repositorio de Archivos adjuntos"],
        [this.rango],
        [],
        ["Tamaño"]
      ]);

      /* add row objects to sheet starting from cell A5 */
      var itemsWS = XLSX.utils.sheet_add_json(ws, report, { header: [], skipHeader: true, origin: "A8" });

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, itemsWS, tableId); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, tableId + '.xlsx'); // name of the file is 'book.xlsx'

    }

  } // END METHODS
}
</script>

<style>
  @import '../../../public/css/report.css';
</style>